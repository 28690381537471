.AboutContainers{
    background-color: #f0f2f5;
}
.About_Session{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(400px,1fr));
    gap: 10px;
    margin-top: 30px;


}
.AboutTitles{
    display: flex;
    justify-content: center;
}
.AboutTitles h2{
    color: black;
    border-bottom: 3px solid #24b969;
    padding: 5px 0px;
    margin-bottom: 5px;
    border-radius: 2px;
    text-align: center;
}
.AboutContente{

    padding: 2px 20px;
    display: flex;
    text-align: justify;
    align-items: center;
}
.BioPhoto{

}

.BoxVisions{
    /*display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
    gap: 20px;
    background-color: #24b969;*/
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    }
    .boxes{
        margin: 10px;
        width: 400px;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        transition: 0.5s all ease;
        text-align: center;
    }
    .boxes h3{
        color: black;
        border-bottom: 3px solid #24b969;
        padding: 5px 0px;
        margin-bottom: 5px;
        border-radius: 2px;
    }
    
    .boxes:hover{
        transform: scale(1.1);
        transition: all 0.5s ease;
        background-color: #24b969;
        color: white;
    }
    .boxes:hover .BoxTitles h3{
        color: white;
        border-bottom: 3px solid white;
        transition: all 0.5s ease;
    }
    
  .img img{
      border-radius: 5px;
  }
  @media screen and (max-width:900px){
    
    .BoxVisions{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
        gap: 20px;
        margin: 0;
    }
    .boxes{
        width: auto;
    }
  }

  @media screen and (max-width:600px){
    .About_Session{
        display: flex;
        flex-wrap: wrap;
    }
    .AboutContente{
        margin: 0;
        padding: 5px;
    }
   
  }
  