.ResourceMain{
    /*display: grid;
    grid-template-columns:repeat(auto-fit, minmax(300px,1fr));
    gap: 20px;*/
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .RessourceBox{
    margin: 10px;
    width: 300px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;

}
.RessourceInfos{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    justify-content: center;
}
.RessourceImage{
    position: relative;
    flex-grow: 1 1 400px;
    height: 200px;
    overflow: hidden;
    border-bottom: 2px solid rgb(194, 194, 194);
    background-color: #24b969;
   
}
.RessourceImage iframe{
    width: 100%;
    height: 100%;
    display: block;
}
.RessourceImage img{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.RessourceImage video{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.RessourceBtn{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
.RessourceBtn a{
    color: #24b969;
    border: 1px solid #24b969;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}
.RessourceBtn a:hover{
    background-color: #24b969;
    color: white;
  }
  .ResourcesContainer .TitlesTeam{
      display: flex;
      justify-content: center;
  }
  .videosLink{
      font-weight: 600;
      color: #24b969;
  }