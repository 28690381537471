.SolutionContainer{
    background-color: #f0f2f5;
}
.Solution_Title{
    display: flex;
    justify-content: center;
}
.Solution_Title h2{
    color: black;
    border-bottom: 3px solid #24b969;
    padding: 5px 0px;
    margin-bottom: 5px;
    border-radius: 2px;
    text-align: center;
}
.SvgContainers{
    width: 100%;
    height: 100px;
}
.SvgContainers svg{
    width: 100%;
    height: 100%;
}
.Solution_sessions{
    /*margin-top: 30px;
    display: flex;*/
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(350px,1fr));
    gap: 10px;
    margin-top: 30px;
   
}
.SolutionPhoto{
position: relative;
padding-top: 56.25%;
overflow: hidden;
order: 2;
padding: 10px;
display: block;
border-radius: 10px;
}
.SolutionPhoto iframe{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.SolutionText{
    order: 1;
    text-align: justify;
    flex-grow: 1;
    padding: 10px;
}


/* Sub Photo*/
.SolutionSubPhoto{
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    }
    .SSubBox{
        background-color: white;
        border-radius: 5px;
        position: relative;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        padding: 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;
    }
    
    .SSubBox i{
        font-size: 2rem;
        padding: 10px;
        color: #24b969;
        transition: all 0.5s ease;
    }
    .SSubBox:hover{
        transform: scale(1.1);
        transition: all 0.5s ease;
        background-color: #24b969;
        color: white;
        transition: all 0.5s ease;
    }
    .SSubBox:hover i{
        color: white;
    }
    .SSubBox span{
        font-weight: 600;
      
    }
    @media screen and (max-width:860px){
        .Solution_sessions{
            display: flex;
            flex-direction: column;
        }
        .SolutionPhoto{
            height: 400px;
        }
      
    }
    @media screen and (max-width:475px){
        .SolutionPhoto{
            height: 250px;
        }
    }
    @media screen and (max-width:350px){
        .SolutionPhoto{
            height: 180px;
        }
    }