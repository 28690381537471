.PrivacyPolicyContainer{
 
    
}

.H1_PrivacyPolicy{
    display: flex;
    justify-content: center;
}

.H1_PrivacyPolicy h1{
    color: black;
    border-bottom: 3px solid #24b969;
    padding: 5px 0px;
    margin-bottom: 5px;
    border-radius: 2px;
    text-align: center;
}

.PrivacyPolicyTitle{
    text-align: center;
}

.PrivacyPolicyMain{
    display: flex;
    flex-direction: column;
    margin-top: -30px;
}

.PrivacyPolicy_sessions{
    display: flex;
     align-items: center;
     margin-top: 30px;
}

.PrivacyPolicyText{
    padding: 0px 20px;
    text-align: justify;
}
