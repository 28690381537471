@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    font-family: 'Open Sans', sans-serif;
    line-height: 1.7;
    font-size: 13px;
    min-height: 100vh;
   
}
.Container{
    max-width: 1100px;
    margin: 0 auto;
    overflow: auto;
    padding: 20px;
}
img{
    display: block;
    width: 100%;
    object-fit: cover;
}
a{
    text-decoration: none;
    cursor: pointer;
}
input, button, textarea{
    font-family: 'Open Sans', sans-serif;
    line-height: 1.4;
    font-size: 12px;
    outline: none; 
}
button{
    cursor: pointer;
    border: none;
}