.ProblemContainer{
 
    
}
.H2_Problems{
    display: flex;
    justify-content: center;
}
.H2_Problems h2{
    color: black;
    border-bottom: 3px solid #24b969;
    padding: 5px 0px;
    margin-bottom: 5px;
    border-radius: 2px;
    text-align: center;
}
/*.H2_Problems h2{
    color: black;
    border-bottom: 3px solid #24b969;
    padding: 5px 0px;
    margin-bottom: 5px;
    border-radius: 2px;
    text-align: center;
}*/
.SubBox .imgbox img{
   
    border-radius: 5px;
}
.SubBox{
    display: flex;
    padding: 1px;
 }
.ProblemMain{
    display: flex;
    flex-direction: column;
    margin-top: -30px;
}
.Problem_sessions{
    display: flex;
     align-items: center;
     margin-top: 30px;
    
}
.ProblemText{
    padding: 0px 20px;
    text-align: justify;
}
@media screen and (max-width:944px){
    .Problem_sessions{
        flex-direction: column;
        align-items: flex-start;
    }
    .ProblemText{
        order: 1;
        padding: 20px;
    }
    .ProblemPhoto{
        width: 100%;
        order: 2;
        padding: 10px;
    }
    .ProblemSubPhoto{
        display: flex; 
    }
  .SubBox{
    position: relative;
    flex-grow: 1;
    margin: 10px;
    overflow: hidden;
  }
  .SubBox .imgbox{
  
   
    overflow: hidden;
  }
  .SubBox .imgbox img{
      object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
    
  }

}
@media screen and (max-width:425px){
.ProblemSubPhoto{
    flex-wrap: wrap;
    
}
}