.PartnersContainers{

    background-color: white;
    width: 100%;
    min-height: 50vh;
    overflow: hidden;
    position: relative;
    display: grid;
}

.Logos{
    width: 100%;
}
.LogoBoxes{
    display: grid;
    grid-template-columns:repeat(auto-fill, minmax(110px, 1fr));
    gap: 20px;
   
}
.LogosTiles{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.LogosTiles h2{
    color: black;
    border-bottom: 3px solid #24b969;
    padding: 5px 0px;
    margin-bottom: 5px;
    border-radius: 2px;
    text-align: center;
}
.Box{
    border:1px solid #e6e5e5e0 ;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease;
}
.Box img{
    display: block;
}
.Box:hover{
    transform: scale(1.2);
   transition: all 0.5s ease;
}

.Svg{
    width: 100%;
    height: 100px;
}
.Svg svg{
    width: 100%;
    height: 100%;
}
