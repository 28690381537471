.ModelContainers{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    z-index: 1000;
  }
  .ModelOvelay{
    background-color: rgba(0, 0, 0, 0.644);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .ModelContainers .ProfileInfos{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    display: grid;
    place-items: center;
  }
  .closeTimes{
      position: absolute;
      right: 0;
      padding: 10px 20px;
      font-size: 18px;
      cursor: pointer;
  }
  .Infos{
    position: relative;
    background-color: white;
    border-radius: 5px;
    width: 70%;
    /*display: grid;
    grid-template-columns: 35% 75%;
    gap: 5px;*/
    display: flex;
    justify-content: space-around;
    z-index: 1000;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    
  }
  .IfosContainers{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
    padding: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
  }
  .IfosContainers a{
    text-decoration: none;
    color: #24b969;
    font-size: 20px;
    padding: 10px;
}

.ProfPhoto{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.ProfPhoto img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;

}
.BiosContent{
  padding: 20px;
  text-align: justify;
}
.BiosTitles{
 
}
.Lines{
  width: 100px;
  height: 5px;
  background-color: #24b969;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}
.Biograph_infos{
  padding: 40px 0px;
}


 /* Copy Start */
 @media screen and (max-width:768px){
  .Infos{
    width: 80%;
    }

 }
 @media screen and (max-width:600px){
  .Infos{
    flex-wrap: wrap;
    overflow: scroll;
    width: 100%;
    height: 100vh;
    border-radius: 0px;

  }
  .IfosContainers{
    position: fixed;
    width: 100%;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .TopBanner{
    background-color: #f0f2f5;
    width: 100%;
    height: 170px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 20px ;
  }
  .IfosContainers .svgContainerss{
    width: 100%;
    height: 50px;
  }
  .svgContainerss svg{
    width: 100%;
    height: 100;
  }
  .BiosContent{
    width: 100%;
    margin-top: 280px;
  }

  .closeTimes{
    position: fixed;
    right: 2%;
    z-index: 1001;
    
   
  }

  .BiosTitles span{
    font-size: 14px;
  }





 }