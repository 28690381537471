.Nav{
    position: fixed;
    display: flex;
    height: 80px;
    border-bottom: 3px solid #e0e0e041;
    background-color: white;
    width: 100%;
    z-index: 1000;
    overflow: hidden;
}
.NavMenu{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Logo{ 
    position: relative;
    cursor: pointer;
    display: flex;
}
.Logo .logoTitle{
    font-size: 20px;
    color: #24b969;
    font-weight: 400;
    flex-grow: 1;
}
.Logo .logoTitle b{
    font-weight: 800;
}
.Logo .ImgLogo{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
}
.Logo .ImgLogo img{
  z-index: 1000;
   width: 8%;
   margin-right: 5px;
  
}
.Navitem{
    display: flex;
    align-items: center;
}
.Navitem a{
    padding: 20px;
    font-weight: 300;
    font-size: 13px;
    
}
.BarIcon{
    display: none;
    font-size: 16px;
    cursor: pointer;
}
.ListItem> .active{
    border-bottom: 2px solid #24b969;
    background-color: #24b96a11;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.Navitem a:hover{
    border-bottom: 2px solid #24b969;
    background-color: #24b96a11;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

@media screen and (max-width:960px){
    .BarIcon{
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        padding: 10px 20px;
    }
    .Navitem{
        width: 100%;
       display: none;
       flex-direction: column;
       justify-content: flex-start;
       align-items: flex-start;
       position: fixed;
       top: 80px;
       left: 0;
       right: 0;
       bottom: 0;
       background-color: white;
       min-height: 100vh;
       transition: all 0.5 ease;
       
    }
    .NavitemActive{
        width: 100%;
       display: flex;
       flex-direction: column;
       justify-content: flex-start;
       align-items: flex-start;
       position: fixed;
       top: 80px;
       left: 0;
       right: 0;
       bottom: 0;
       background-color: white;
       min-height: 100vh;
       transition: all 0.5 ease;
    }
  .ListItem{
        display: flex;
        width: 100%;
    }
.ListItem a{
        padding: 15px 50px;
        flex-grow: 1;
        border-bottom: 1px solid #e0e0e041;
        border-left: 3px solid #afc9b9;
        font-weight: 300;
        font-size: 13px;
    }
    .ListItem a:hover{
        border-bottom: 2px solid #24b969;
        background-color: #24b96a11;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    
}
