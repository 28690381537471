.BannerContaines{
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    /*flex-direction: column;*/
    overflow: hidden;
   
}
.Ovelrays{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 6;
    
}
.IconsDirect{
    margin-top: 300px;
    cursor: pointer;
}
.IconsDirect i{
    font-size: 50px;
    padding: 10px;
    cursor: pointer;
}
section{
    display: flex;
    position: absolute;
    width: 100%;
    min-height: 100vh;
    background-color: black;
    opacity: 0;
    margin-top: 50px;
   
}
.ActiveSlide{
    opacity: 1;
    transform: translateX(0);
}
.NextSlide{
   transform: translateX(-100%);
}
.LastSlide{
    transform: translateX(100%);
}

.Imgbag{
    position: relative;
    width: 100%;
}
.Imgbag img{
    width: 100%;
    height: 100%;
}
.Imgbag::before{
    content:'';
    position: absolute;
    top: 0;
    left:0;
    right:0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.486)0%,rgba(0,0,0,0.6)100%),linear-gradient(180deg, rgba(133, 35, 35, 0.2)0%, transparent 100%);
    z-index: 2;
}
.Contente{
    position: absolute;
    top: 0;
    left:0;
    right:0;
    bottom: 0;
    z-index: 3;
 
    overflow: hidden;
  

}
.BannerMainContent{
   display: flex;
   height: 100%;
   align-items: center;
   margin-top: -150px;
   /*margin-top: 150px;*/

}
.BannerMainContent .DummSpan{
    
    width: 75%;
}
.LeftContent{
    display: flex;
    flex-direction: column;
    text-align: center;

}
.LeftContent .Slogann{
    color: white;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 18px;
    position: relative;
    display: inline-block;
}
.LeftContent .Slogann::before{
    content: '';
    display: block;
    width: 90px;
    bottom: 7px;
    height: 3px;
    background-color: #24b969;
    position: absolute;
   
}
.LeftContent .Slogann::after{
    content: '';
    display: block;
    width: 90px;
    right: 0;
    bottom: 0;
    height: 3px;
    background-color: #24b969;
    bottom: 7px;
    position: absolute;
}
.Slogann p{
    color: white;
}
.Contente .BannersTitle{
    font-weight: 800;
    font-size: 4em;
    color: #24b969;
}
.Contente .BannerContente{
    font-size: 20px;
    color: white;
}
.SvgContainer{
    position: absolute;
    overflow: hidden;
    bottom: 0;
    width: 100%;
    height: 50px;
    z-index: 4;
}
.SvgContainer svg {
 position: absolute;
 width: 100%;
 height: 100%;
}
@media screen and (max-width:960px){
    .DummSpan{
        display: none;
    }
    .BannerMainContent{
        justify-content: center;
        margin-top: -90px;
    }
    .LeftContent .Slogann::before{
        content: '';
        left: 80px;
        display: block;
        width: 70px;
        bottom: 7px;
        height: 3px;
        background-color: #24b969;
        position: absolute;
       
    }
    .LeftContent .Slogann::after{
        content: '';
        display: block;
        width: 70px;
        right: 80px;
        bottom: 0;
        height: 3px;
        background-color: #24b969;
        bottom: 7px;
        position: absolute;
    }
   
    
}
@media screen and (max-width:600px){
    .LeftContent .Slogann::before{
        content: '';
        display: block;
        left: 60px;
        width: 70px;
        bottom: -7px;
        height: 3px;
        background-color: #24b969;
        position: absolute;
       
    }
    .LeftContent .Slogann::after{
        content: '';
        display: block;
        width: 70px;
        right: 60px;
        height: 3px;
        background-color: #24b969;
        bottom: -7px;
        position: absolute;
    }

 
}
@media screen and (max-width:600px){
    .LeftContent .BannersTitle{
        font-size: 40px;
    }
  
}