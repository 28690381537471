.TeamContainers{
    background-color: #f0f2f5;
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;

}
.BiomastersTeam, .BoardMembers{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.TeamBox{
    
    /*flex: 1 1 250px;*/
    width: 300px;
    margin: 10px;
    background-color:white;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.6);
  
}
.BannersBox{
    height: 160px;
    position: relative;
}
.BannersBox svg{
    width: 100%;
    height: 100%;
}
.BannerBackgrounds{
    background-color: #f0f2f5;
    /*background-color: #e0e0e0;*/
    height: 100px;
}
.ImageBoxes{
    position: relative;
    display: flex;
    justify-content: center;
    
}
.ImageMain{
    width: 180px;
    height: 180px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: -150px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.6);
}
.ImageMain img{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.TeamInfos{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.TeamInfos h2{
    display: flex;
    font-weight: 300px;
    font-size: 18px;
}
.TeamInfos p{
    font-weight: 600px;
    font-size: 15px;
    
   
}
.Names{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Names p{
    padding-left: 5px;
    font-size: 18px;
}
.SocialInfos{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e0e0e0;
}
.SocialInfos a{
    flex-grow: 1;
    text-align: right;
}

.SocialInfos i{
    font-size: 20px;
    padding: 10px;
    color: #24b96a9d;
   
}

.ReadMores{
    flex-grow: 1;
    cursor: pointer;
    overflow: hidden;
}
.ReadMores i{
    pointer-events: none;
}
.ReadMores span{
  opacity: 0;
  transition: all 0.5s ease-in-out;
  pointer-events: none;
}
.TitlesCss{
    color: #24b969;
    font-weight: 600;
    
}
.TitlesTeam h2{
    color: black;
    border-bottom: 3px solid #24b969;
    padding: 5px 0px;
    margin-bottom: 5px;
    border-radius: 2px;
}
.TeamBox:hover .ReadMores span{
    opacity: 1;
}
@media screen and (max-width:768px){
    .ReadMores span{
        opacity: 1;
        transition: all 0.5s ease-in-out;
        pointer-events: none;
      }
     
}
@media screen and (max-width:320px){
    .TeamBox{
        width: 260px;
    }

}
