.ContactSvgContainers{
    position: relative;
    height: 50px;
    overflow: hidden;

}
.ContactSvgContainers svg{
    width: 100%;
    height: 100%;

    
}
.ContainerContactNew{
    background-color: #32704a;
}
.ContactForm{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
}
.FormContainers{
    display: flex;
    flex-direction: column;
}
.FormContainers h2{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.FormContainers h2 p{
    border-bottom: 3px solid white;
    margin-bottom: 20px;
}
.FormTextBoxes{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 10px;
   
}
.BoxInputs{
    display: flex;
    flex-direction: column;
}
.FormTextBoxes input, textarea{
    background-color: rgba(255, 255, 255, 0.877);
    outline: none;
     border: none;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
}
.FormBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}
.FormBtn button{
    color: #32704a;
    font-size: 1em;
    padding:10px;
    background-color: white;
    font-weight: 800;
    border-radius: 5px;
}
.FormBtn button:hover{
    background-color: #24b969;
    color: white;
}
.ContactBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: white;
}
.ContactBox h3 i{
    padding: 10px;
    font-size: 14px;
}
.ContactBox .ContactInfos{
    margin-left: 40px;
}
.btnText{
    background-color: #f3f3f3;
    display: flex;
    overflow: hidden;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    margin-top: 5px;
}
.btnText input{
flex-grow: 1;
padding: 0px 10px;
outline: none;
border: none;
background-color: transparent;
}
.btnText button{
    color: #32704a;
    font-size: 1em;
    padding:10px;
    background-color: white;
    font-weight: 800;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}
.btnText button:hover{
    background-color: #24b969;
    color: white;
}
.Copyright{
    background-color:#265537fa;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
   color: white;
   font-weight: 600;
}
.errors{
    color: rgb(255, 73, 73);
    padding: 5px;
}
.Social_Icons{
    color: white;
}
.Social_Icons a{
    color: white;
}
.Social_Icons a i{
    padding: 10px 0px;
    font-size: 20px;
}
.Subscribe_btns{
    margin: 15px 0;
    padding: 5px;
    border: 1px solid rgba(255, 255, 255, 0.732);
    display: flex;
    justify-content: center;
    flex-grow: 1;
    color: white;
    font-weight: 500;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width:768px){
    .ContactForm{
      grid-template-columns: 1fr;
    
    }
  
    .ContactUs{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 20px;
    }
   
 
}
@media screen and (max-width:420px){
    .btnText input{
        flex-grow: 1;
    }
    .btnText button{
        font-size: 11px;
        flex-grow: 1;
    }
}